import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import * as colors from '../styles/colors'

interface IconBtnProps {
  children: React.ReactElement<React.SVGProps<HTMLOrSVGElement>>
  className: string
}

function IconBtn({ children, className, ...props }: IconBtnProps) {
  return React.cloneElement(children, {
    ...props,
    className: classNames(className, children.props.className)
  })
}

export default styled(IconBtn)`
  fill: currentColor;

  &:focus,
  &:hover,
  [aria-current] & {
    fill: ${colors.accent};
  }
`
