import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import * as colors from '../styles/colors'
import * as typography from '../styles/typography'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: string | React.ReactElement<any>
  className?: string
}

function Button(props: ButtonProps) {
  const { children, className, ...restProps } = props
  const element =
    typeof children === 'string' ? <button>{children}</button> : children
  return React.cloneElement(element, {
    className: classNames(className, element.props.className),
    ...restProps
  })
}

export default styled(Button)`
  background: none;
  border: 2px solid #dadada;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: ${typography.fontSizeSmall};
  font-weight: 700;
  padding: 0.5em 1.25em;

  &:focus,
  &:hover {
    border-color: ${colors.accent};
    outline: 0;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`
