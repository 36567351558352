import { action, computed, observable } from 'mobx'
import differenceInDays from 'date-fns/difference_in_days'
import isToday from 'date-fns/is_today'
import parseDate from 'date-fns/parse'

import apiStore from './api'
import persist from './persist'

export interface Streak {
  begin: string
  end: string
}

export interface Activity {
  newestEntryDate: string
  newestEntryId: string
  oldestEntryDate: string
  oldestEntryId: string
  streak: Streak
  totalEntries: number
  totalTasks: number
}

class ActivityStore {
  @observable
  activity: Activity = null

  @observable
  loaded = false

  @observable
  loading = false

  @computed
  get didJournalToday() {
    if (!this.activity) {
      return null
    }

    return isToday(this.activity.newestEntryDate)
  }

  @computed
  get isStreaking() {
    const streak = this.activity && this.activity.streak
    if (!streak) {
      return null
    }

    if (!streak.end) {
      return null
    }

    const now = new Date()
    const end = parseDate(streak.end)
    return differenceInDays(now, end) <= 1 && this.streakCount >= 1
  }

  @computed
  get streakCount() {
    const streak = this.activity && this.activity.streak
    if (!streak || !streak.begin) {
      return null
    }

    return differenceInDays(parseDate(streak.end), parseDate(streak.begin)) + 1
  }

  async fetch() {
    this.loading = true
    const response = await apiStore.request<Activity>('/activity/')
    this.setActivity(response.data.data)
    this.loaded = true
    this.loading = false
  }

  @action
  setActivity(activity: Activity) {
    this.activity = activity
  }

  @action
  reset() {
    this.activity = null
    this.loaded = false
    this.loading = false
  }

  @action
  updateActivity(activity: Partial<Activity>) {
    this.activity = {
      ...this.activity,
      ...activity
    }
  }
}

const activityStore = new ActivityStore()
persist(activityStore, 'activity')
export default activityStore
