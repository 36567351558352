import { action, computed, observable } from 'mobx'
import apiStore from './api'
import endOfMonth from 'date-fns/end_of_month'
import formatDate from 'date-fns/format'

import activityStore from './activity'
import persist from './persist'

export interface Entry {
  content: string
  date: string
  id: string
  title: string
  updatedAt: string
}

class EntriesStore {
  @observable
  byId: {
    [id: string]: Entry
  } = {}

  @observable
  ids: string[] = []

  @computed
  get entries(): Entry[] {
    return this.ids.map(id => this.byId[id])
  }

  @observable
  loaded = false

  @observable
  loading = false

  constructor() {
    this.updateEntry = this.updateEntry.bind(this)
  }

  async fetchAll({ date = new Date() }: { date: Date }) {
    const toDate = endOfMonth(date)
    const from = formatDate(date, 'YYYY-MM-DD')
    const to = formatDate(toDate, 'YYYY-MM-DD')

    this.loading = true
    const response = await apiStore.request<Entry[]>(
      `/entries?from=${from}&to=${to}`
    )
    this.loading = false
    this.populateEntries(response.data.data)
    this.loaded = true
    return response
  }

  async fetchById(id: string) {
    this.loading = true
    const response = await apiStore.request<Entry>(`/entries/${id}`)
    this.loading = false
    this.addEntry(response.data.data)
    return response
  }

  async fetchEntryContent(id: string) {
    const response = await apiStore.request<string>(`/entries/${id}/content`)

    // Yes, I am aware of how disgusting this is.
    return (response.data as unknown) as string
  }

  @action
  populateEntries(entries: Entry[]) {
    this.byId = entries.reduce((byId, entry) => {
      return {
        ...byId,
        [entry.id]: entry
      }
    }, {})
    this.ids = entries.map(entry => entry.id)
  }

  @action
  addEntry(entry: Entry) {
    this.byId[entry.id] = entry
    if (this.ids.indexOf(entry.id) < 0) {
      this.ids.push(entry.id)
    }
  }

  @action
  removeEntry(entry: Entry) {
    delete this.byId[entry.id]
    const entryIndex = this.ids.indexOf(entry.id)
    this.ids.splice(entryIndex, 1)
  }

  @action
  reset() {
    this.byId = {}
    this.ids = []
    this.loaded = false
  }

  async createEntry(date = new Date()) {
    const dateStr = formatDate(date, 'YYYY-MM-DD')
    const response = await apiStore.request<Entry>({
      url: '/entries',
      method: 'post',
      data: {
        date: dateStr
      }
    })
    await activityStore.fetch()

    const entry = response.data.data
    this.addEntry(entry)
    return entry
  }

  async updateEntry(id: string, data: Partial<Entry>) {
    // Update entry locally if we have it
    if (this.ids.indexOf(id) >= 0) {
      this.byId[id] = {
        ...this.byId[id],
        ...data
      }
    }

    const response = await apiStore.request<Entry>({
      url: `/entries/${id}`,
      method: 'put',
      data
    })
    this.addEntry(response.data.data)
  }
}

const entriesStore = new EntriesStore()
persist(entriesStore, 'entries')
export default entriesStore
