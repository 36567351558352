import React from 'react'
import styled from 'styled-components'

import * as typography from '../styles/typography'
import spacing from '../styles/spacing'

export interface FormFieldProps {
  children: React.ReactNode
  label: React.ReactNode
}

const Wrapper = styled.label`
  display: block;
  margin-bottom: ${spacing(3)};
`

const Label = styled.span`
  display: block;
  font-size: ${typography.fontSizeSmall};
  font-weight: 700;
  margin-bottom: ${spacing()};
`

export default function FormField(props: FormFieldProps) {
  return (
    <Wrapper>
      <Label>{props.label}</Label>
      {props.children}
    </Wrapper>
  )
}
