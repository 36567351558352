import { action, observable } from 'mobx'
import axios, { AxiosRequestConfig } from 'axios'
import persist from './persist'

const api = axios.create({
  baseURL: process.env.API_URL
})

export interface ApiResponse<DataType, MetaType = {}> {
  data: DataType
  meta?: MetaType
}

class ApiStore {
  @observable
  token: string = null

  request<DataType, MetaType = {}>(options: string | AxiosRequestConfig) {
    type ResponseType = ApiResponse<DataType, MetaType>

    if (typeof options === 'string') {
      options = {
        method: 'get',
        url: options
      }
    }

    if (this.token) {
      options.headers = {
        Authorization: `Bearer ${this.token}`,
        ...options.headers
      }
    }

    return api.request<ResponseType>(options)
  }

  @action
  setToken(token: string) {
    this.token = token
  }

  @action
  clearToken() {
    this.token = null
  }
}

const apiStore = new ApiStore()

persist(apiStore, 'api')

export default apiStore
