import React, { Component, FormEvent } from 'react'
import { findDOMNode } from 'react-dom'
import styled from 'styled-components'

export interface FormProps {
  autofocus?: boolean
  maxWidth?: boolean
  onSubmit: (event: FormEvent) => void
}

const StyledForm = styled.form`
  max-width: ${props => (props.maxWidth ? 'none' : '30rem')};
`

class Form extends Component<FormProps> {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.autofocus) {
      const node = findDOMNode(this) as Element
      const firstInput = node.querySelector(
        'input, textarea'
      ) as HTMLInputElement
      if (firstInput) {
        firstInput.focus()
      }
    }
  }

  render() {
    const { onSubmit, ...props } = this.props
    return <StyledForm onSubmit={this.onSubmit} {...props} />
  }

  onSubmit(event: FormEvent) {
    event.preventDefault()
    this.props.onSubmit(event)
  }
}

export default Form
