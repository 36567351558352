import { computed, observable } from 'mobx'

import activityStore from './activity'
import apiStore from './api'
import entriesStore from './entries'
import tasksStore from './tasks'
import userStore, { User } from './user'

export interface Credentials {
  email: string
  password: string
}

class AuthStore {
  @observable
  email = ''

  @observable
  password = ''

  @computed
  get isAuthenticated() {
    return userStore.user !== null && apiStore.token !== null
  }

  async createAccount(credentials: Credentials) {
    const userResponse = await apiStore.request<User>({
      method: 'post',
      url: '/users',
      data: credentials
    })

    return userResponse.data.data
  }

  async login(credentials: Credentials) {
    const tokenResponse = await apiStore.request<string>({
      method: 'post',
      url: 'authenticate',
      data: credentials
    })
    const token = tokenResponse.data.data

    const userResponse = await apiStore.request<User>({
      method: 'get',
      url: '/users/whoami',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    this.email = ''
    this.password = ''

    apiStore.setToken(tokenResponse.data.data)
    userStore.setUser(userResponse.data.data)
  }

  logout() {
    activityStore.reset()
    apiStore.clearToken()
    entriesStore.reset()
    tasksStore.reset()
    userStore.clearUser()
  }
}

export default new AuthStore()
