import { createGlobalStyle } from 'styled-components'

import * as colors from './colors'
import spacing from './spacing'
import { fontSize, fontSizeBase } from './typography'

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  html {
    background: ${colors.bg};
    box-sizing: border-box;
    color: ${colors.text};
    font-family: 'Inconsolata', monospace;
    font-size: ${fontSizeBase};
    line-height: 1.5;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3 {
    line-height: 1.25;
    margin-bottom: ${spacing(1)};
  }

  h1,
  h2 {
    font-weight: 400;
  }

  h1 {
    font-size: ${fontSize(3)};
  }

  h2 {
    font-size: ${fontSize(2)};
  }

  h3 {
    font-size: ${fontSize(1)};
  }

  p {
    margin-bottom: ${spacing(2)};
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  form,
  input,
  textarea,
  button {
    font: inherit;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  form {
    margin: 0;
  }

  section {
    margin-bottom: ${spacing(4)};
  }

  [role=button] {
    cursor: pointer
  }
`

export default GlobalStyle
