import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import * as colors from '../styles/colors'

export interface TextButtonProps {
  active: boolean
  children: React.ReactElement<any>
  className?: string
}

/**
 * Styles an element that contains text to look like it is clickable.
 */
function TextButton(props: TextButtonProps) {
  const { className, children, ...restProps } = props
  return React.cloneElement(props.children, {
    className: classNames(className, props.children.props.className),
    ...restProps
  })
}

const TextButtonActiveStyles = `
  border-bottom-color: ${colors.accent};
  border-bottom-width: 2px;
  outline: 0;
  padding-bottom: 0;
`

export default styled(TextButton)`
  border-bottom: 1px solid currentColor;
  display: inline-block;
  padding-bottom: 1px;
  text-decoration: none;

  &:focus,
  &:hover {
    ${TextButtonActiveStyles};
  }

  ${(props: TextButtonProps) =>
    props.active ? TextButtonActiveStyles : ''} &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`
