import { action, observable } from 'mobx'
import persist from './persist'

export interface User {
  email: string
}

class UserStore {
  @observable
  user: User = null

  @action
  clearUser() {
    this.user = null
  }

  @action
  setUser(user: User) {
    this.user = user
  }
}

const userStore = new UserStore()

persist(userStore, 'user')

export default userStore
