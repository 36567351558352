import { action, observable, computed } from 'mobx'

import apiStore from './api'
import persist from './persist'

export interface Task {
  id: string
  completed: boolean
  description: string
  elementId: string
  entryId: string
  createdAt: string
}

interface TasksMeta {
  count: number
  pages: number
}

class Tasks {
  @observable
  byId: {
    [id: string]: Task
  } = {}

  @observable
  ids: string[]

  @observable
  meta: TasksMeta = null

  @computed
  get tasks() {
    return this.ids.map(id => this.byId[id])
  }

  @computed
  get completedTasks() {
    return this.tasks.filter(task => task.completed)
  }

  @computed
  get uncompletedTasks() {
    return this.tasks.filter(task => !task.completed)
  }

  async fetchTasks({
    entryId,
    completed,
    page = 1
  }: {
    entryId?: string
    completed?: boolean
    page?: number
  }) {
    const params: {
      entryId?: string
      completed?: string
      page: number
    } = {
      entryId,
      page
    }

    if (typeof completed === 'boolean') {
      params.completed = completed ? 'true' : 'false'
    }

    const response = await apiStore.request<Task[], TasksMeta>({
      url: '/tasks',
      params
    })
    this.loadTasks({
      data: response.data.data,
      meta: response.data.meta
    })
    return response
  }

  async createTask(entryId: string, description: string) {
    const taskData = {
      entryId,
      description
    }
    const response = await apiStore.request<Task>({
      url: '/tasks',
      method: 'post',
      data: taskData
    })
    const { data } = response.data
    this.setTask(data)
    return data
  }

  async deleteTask(taskId: string) {
    this.removeTask(taskId)
    return await apiStore.request({
      url: `/tasks/${taskId}`,
      method: 'delete'
    })
  }

  async updateTask(taskId: string, data: Partial<Task>) {
    this.byId[taskId] = {
      ...this.byId[taskId],
      ...data
    }
    const response = await apiStore.request<Task>({
      url: `/tasks/${taskId}`,
      method: 'put',
      data
    })
    this.byId[taskId] = response.data.data
    return this.byId[taskId]
  }

  getTasksForEntry(entryId: string) {
    return this.ids
      .map(id => this.byId[id])
      .filter(task => task.entryId === entryId)
  }

  @action
  private loadTasks({ data, meta }: { data: Task[]; meta: TasksMeta }) {
    const byId = {}
    const ids = []

    data.forEach(task => {
      byId[task.id] = task

      if (ids.indexOf(task.id) < 0) {
        ids.push(task.id)
      }
    })

    this.byId = byId
    this.ids = ids
    this.meta = meta
  }

  @action
  private removeTask(id: string) {
    delete this.byId[id]
    this.ids = this.ids.filter(i => i !== id)
  }

  @action
  private setTask(task: Task) {
    this.byId[task.id] = task
    if (this.ids.indexOf(task.id) < 0) {
      this.ids.unshift(task.id)
    }
    task
  }

  @action
  reset() {
    this.byId = {}
    this.ids = []
    this.meta = null
  }
}

const tasksStore = new Tasks()
persist(tasksStore, 'tasks')
export default tasksStore
