export const spacingUnit = '10px'

/**
 * Helps to maintain consistent spacing between elements.
 * @param numUnits The number of spacing units.
 */
export default function spacing(numUnits = 1) {
  // No need to do maths if the spacing unit is just one.
  if (numUnits === 1) {
    return spacingUnit
  }

  return `calc(${spacingUnit} * ${numUnits})`
}
