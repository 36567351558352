import Loadable from 'react-loadable'
import React, { Component } from 'react'
import { Router, Redirect } from '@reach/router'
import { observer } from 'mobx-react'

import Spinner from './components/spinner'
import authStore from './stores/auth'

const About = Loadable({
  loader: () => import('./about/about'),
  loading: Spinner
})

const Entries = Loadable({
  loader: () => import('./entries/entries'),
  loading: Spinner
})

const Entry = Loadable({
  loader: () => import('./entries/entry'),
  loading: Spinner
})

const Login = Loadable({
  loader: () => import('./login/login'),
  loading: Spinner
})

const NotFound = Loadable({
  loader: () => import('./not-found/not-found'),
  loading: Spinner
})

const Register = Loadable({
  loader: () => import('./register/register'),
  loading: Spinner
})

const Tasks = Loadable({
  loader: () => import('./tasks/tasks'),
  loading: Spinner
})

export interface RoutesProps {
  className?: string
}

@observer
class Routes extends Component<RoutesProps> {
  render() {
    return (
      <Router component="main" {...this.props}>
        <About path="/about" />
        {authStore.isAuthenticated ? (
          <Redirect from="/" to="/entries" />
        ) : (
          <Redirect from="/" to="/login" />
        )}
        {authStore.isAuthenticated ? (
          <Redirect from="/login" to="/" />
        ) : (
          <Login path="/login" />
        )}
        {authStore.isAuthenticated ? (
          <Redirect from="/register" to="/" />
        ) : (
          <Register path="/register" />
        )}
        {authStore.isAuthenticated ? (
          <Entries path="/entries" />
        ) : (
          <Redirect from="/entries" to="/login" />
        )}
        {!authStore.isAuthenticated ? (
          <Redirect from="/entries/*" to="/login" />
        ) : null}
        {authStore.isAuthenticated ? <Entries path="/entries/:date" /> : null}
        {authStore.isAuthenticated ? (
          <Entry path="/entries/:entryId/edit" />
        ) : null}
        {authStore.isAuthenticated ? (
          <Tasks path="/tasks" />
        ) : (
          <Redirect from="/tasks" to="/login" />
        )}
        <NotFound default />
      </Router>
    )
  }
}

export default Routes
