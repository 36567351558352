import hexRGB from 'hex-rgb'

export const bg = '#fff'

export const text = '#444'
export const textDark = '#222'
export const textLight = '#888'

export const accent = '#19cf7d'
export const red = '#eb4f56'

export function rgba(hexColor: string, alpha: number) {
  const { red, green, blue } = hexRGB(hexColor)
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}
