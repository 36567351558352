export const fontScaleRatio = 1.25
export function fontSize(scale: number) {
  return `${Math.pow(fontScaleRatio, scale)}rem`
}

export const fontSizeBase = '14px'
export const fontSizeSmall = '0.9rem;'
export const fontSizeLarge = '1.2rem;'

export const lineHeight = 1.5
