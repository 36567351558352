import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

function TextInput(props) {
  const element =
    typeof props.children === 'string' ? (
      <input>{props.children}</input>
    ) : (
      props.children
    )
  return React.cloneElement(element, {
    className: classNames(props.className)
  })
}

export default styled(TextInput)`
  background: #eaeaea;
  border: 0;
  display: block;
  padding: 0.75em 1em;
  width: 100%;

  &:focus,
  &:hover {
    background: #f4f4f4;
    outline: 0;
  }

  &[disabled] {
    background: #eaeaea;
    opacity: 0.5;
    pointer-events: none;
  }
`
