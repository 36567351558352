import { Link } from '@reach/router'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import * as colors from '../styles/colors'
import spacing from '../styles/spacing'
import AriaButton from '@restlessbit/aria-button'
import CheckOutlineIcon from '../images/check-outline.svg'
import IconBtn from '../components/icon-btn'
import ListIcon from '../images/list.svg'
import LoginIcon from '../images/log-in.svg'
import LogoutIcon from '../images/log-out.svg'
import NightModeBtn from '../night-mode/night-mode-btn'
import authStore from '../stores/auth'
import userStore from '../stores/user'

const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing(3)};
  margin-top: ${spacing()};
`

const NavSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 33.333%;

  &:nth-child(2) {
    justify-content: center;
  }

  &:nth-child(3) {
    justify-content: flex-end;
  }
`

const NavItem = styled.span`
  margin-right: ${spacing(2)};

  &:last-child {
    margin-right: 0;
  }
`

const NavLink = styled(Link)`
  &:focus,
  &:hover {
    color: ${colors.accent};
    outline: 0;
  }

  &[aria-current] {
    font-weight: 700;
  }
`

const LogoLink = styled(Link)`
  font-weight: 700;

  &,
  &:focus,
  &:hover {
    border: 0;
    padding: 0;
  }

  &:focus,
  &:hover {
    color: ${colors.accent};
  }
`

export interface NavigationProps {
  className?: string
}

@observer
class Navigation extends Component<NavigationProps> {
  node: Element

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.node = findDOMNode(this) as Element
    document.addEventListener('click', this.onClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick)
  }

  render() {
    return (
      <Nav {...this.props}>
        <NavSection>
          <NavItem>
            {authStore.isAuthenticated ? (
              <Link to="/entries" aria-label="View your entries">
                <IconBtn>
                  <ListIcon width={20} />
                </IconBtn>
              </Link>
            ) : (
              <NavLink to="/about">About</NavLink>
            )}
          </NavItem>
          <NavItem>
            {authStore.isAuthenticated ? (
              <Link to="/tasks" aria-label="View your tasks">
                <IconBtn>
                  <CheckOutlineIcon width={20} />
                </IconBtn>
              </Link>
            ) : null}
          </NavItem>
        </NavSection>
        <NavSection>
          <LogoLink to={authStore.isAuthenticated ? '/entries' : '/'}>
            Changelog
          </LogoLink>
        </NavSection>
        <NavSection>
          <NavItem>
            <NightModeBtn />
          </NavItem>
          <NavItem>
            {userStore.user ? (
              <AriaButton aria-label="Log out" onClick={authStore.logout}>
                <IconBtn>
                  <LogoutIcon width={20} />
                </IconBtn>
              </AriaButton>
            ) : (
              <NavLink to="/login">
                <IconBtn>
                  <LoginIcon width={20} />
                </IconBtn>
              </NavLink>
            )}
          </NavItem>
        </NavSection>
      </Nav>
    )
  }

  onClick() {
    const { activeElement } = document
    if (activeElement && this.node.contains(activeElement)) {
      ;(activeElement as HTMLElement).blur()
    }
  }
}

export default Navigation
