import { action, observable } from 'mobx'
import persist from './persist'

class SettingsStore {
  constructor() {
    this.toggleNightMode = this.toggleNightMode.bind(this)
  }

  @observable
  nightMode = false

  @action
  toggleNightMode() {
    this.nightMode = !this.nightMode
  }
}

const settingsStore = new SettingsStore()
persist(settingsStore, 'settings')
export default settingsStore
