import React from 'react'
import styled from 'styled-components'

import Loading from '../images/loading.gif'
import spacing from '../styles/spacing'

const Wrapper = styled.span`
  display: flex;
  padding: ${spacing(2)} ${spacing(1)};
  justify-content: center;
  width: 100%;
`

export default function Spinner() {
  return (
    <Wrapper>
      <img alt="Loading" src={Loading} height={35} />
    </Wrapper>
  )
}
