import Helmet from 'react-helmet'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import styled, { ThemeProvider } from 'styled-components'

import * as themes from './styles/themes'
import spacing from './styles/spacing'
import GlobalStyle from './styles/global'
import Navigation from './navigation/navigation'
import Routes from './routes'
import settingsStore from './stores/settings'

const StyledRoot = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-height: 100vh;
  padding-left: ${spacing()};
  padding-right: ${spacing()};
`

const StyledNav = styled(Navigation)`
  flex: 0 0 auto;
  width: 100%;
`

const StyledRoutes = styled(Routes)`
  flex: 1 1 auto;
  width: 100%;
`

@observer
class Root extends Component {
  rootEl: HTMLElement

  componentDidMount() {
    this.rootEl = document.querySelector('html')
    this.updateRootElTheme()
  }

  componentWillReact() {
    this.updateRootElTheme()
  }

  render() {
    return (
      <ThemeProvider theme={this.getTheme()}>
        <StyledRoot>
          <GlobalStyle />
          <Helmet>
            <title>Changelog</title>
          </Helmet>
          <StyledNav />
          <StyledRoutes />
        </StyledRoot>
      </ThemeProvider>
    )
  }

  getTheme() {
    return settingsStore.nightMode ? themes.dark : themes.light
  }

  updateRootElTheme() {
    const theme = this.getTheme()
    this.rootEl.style.background = theme.background
    this.rootEl.style.color = theme.color
  }
}

export default Root
