import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import * as colors from '../styles/colors'
import AriaButton from '@restlessbit/aria-button'
import MoonIcon from '../images/moon.svg'
import settingsStore from '../stores/settings'

interface StyledIconProps {
  enabled: boolean
}

const StyledBtn = styled(AriaButton)`
  display: block;
  cursor: pointer;
`

const StyledIcon = styled(MoonIcon)`
  fill: ${(props: StyledIconProps) => (props.enabled ? colors.accent : 'none')};
  stroke: ${(props: StyledIconProps) =>
    props.enabled ? colors.accent : 'currentColor'};

  &:focus,
  &:hover {
    fill: ${colors.accent};
    stroke: ${colors.accent};
  }
`

@observer
class NightModeBtn extends React.Component {
  render() {
    const label = settingsStore.nightMode
      ? 'Disable night mode'
      : 'Enable night mode'
    return (
      <StyledBtn aria-label={label} onClick={settingsStore.toggleNightMode}>
        <StyledIcon enabled={settingsStore.nightMode} height={18} />
      </StyledBtn>
    )
  }
}

export default NightModeBtn
