import { autorun, set, toJS } from 'mobx'

/**
 * Updates a MobX store to save and load its state from localStorage.
 * @param store The store to persist.
 * @param storageKey The key for the store's state in localStorage.
 */
export default function persist(store, storageKey: string) {
  let loaded = false

  autorun(() => {
    if (!loaded) {
      const saved = window.localStorage.getItem(storageKey)
      if (saved) {
        set(store, JSON.parse(saved))
      }
      loaded = true
    }

    window.localStorage.setItem(storageKey, JSON.stringify(toJS(store)))
  })
}
