import React from 'react'
import styled from 'styled-components'

import * as colors from '../styles/colors'

const StyledFormError = styled.span`
  color: ${colors.red};
  font-weight: 700;
`

export interface FormErrorProps {
  children: React.ReactNode
}

export default function FormError(props: FormErrorProps) {
  return <StyledFormError>{props.children}</StyledFormError>
}
